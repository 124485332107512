import type { StockStatus } from '@/app/[locale]/product/_types/inventoryTypes';
import type { ListProduct, Variant } from '@/queries/Products';

export enum Event {
  ArticleLandingPage = 'article_landing_page',
  B2bModalOpened = 'b2b_email_signup_modal_opened',
  B2bModalCompleted = 'b2b_email_signup_completed',
  CartViewed = 'cart_viewed',
  CheckoutNewStepCompleted = 'checkout_new_step_completed',
  CheckoutOrderCompleted = 'checkout_order_complete',
  CheckoutStarted = 'checkout_started',
  CheckoutStepCompleted = 'checkout_step_completed',
  CheckoutStepViewed = 'checkout_step_viewed',
  CTAClicked = 'CTA Clicked',
  EmailOOSNotificationSignup = 'Email OOS Notification Signup',
  EmailSignupStarted = 'Email Signup Started',
  EmailSignupCompleted = 'Email Signup Completed',
  GenerateLead = 'generate_lead',
  HamburgerClicked = 'Hamburger Clicked',
  ImageSwitched = 'image_switched',
  LinkClicked = 'link_clicked',
  ManualSizeSelected = 'manual_size_selected',
  MarketingOptIn = 'Marketing Opt-In - Purchase',
  MarketingOptInFooter = 'Marketing Opt-In - Footer',
  ModuleClicked = 'Module Clicked',
  ModuleHovered = 'Module Hovered',
  PageScrolled = 'Page Scrolled',
  ProcessNewConsent = 'processNewConsent',
  ProductAdded = 'product_to_cart',
  ProductClicked = 'product_clicked',
  ProductRemoved = 'product_to_remove_cart',
  ProductViewed = 'product_viewed',
  SizingKitSelected = 'sizing_kit_selected',
  PlaceOrderButtonClicked = 'Place Order Button Clicked',
  UserEmailCaptured = 'User Email Captured',
  Test = 'Test',
  OneClickPaymentButtonClicked = 'One Click Payment Button Clicked',
  D2CGiftCardBalanceClicked = 'D2C Gift Card Balance Clicked',
  D2CGiftCardPurchaseClicked = 'D2C Gift Card Purchase Clicked',
  B2BGiftCardPurchaseClicked = 'B2B Gift Card Purchase Clicked',
}

export const EventType = { ...Event };
export type EventType = Event;

export enum GA4Name {
  AddPaymentInfo = 'add_payment_info',
  AddShippingInfo = 'add_shipping_info',
  AddToCart = 'add_to_cart',
  BeginCheckout = 'begin_checkout',
  Purchase = 'purchase',
  RemoveFromCart = 'remove_from_cart',
  ViewCart = 'view_cart',
  ViewItem = 'view_item',
}

export enum AnalyticsName {
  CartViewed = 'Cart Viewed',
  CheckoutEmailAdded = 'CheckoutEmailAdded', // no spaces are required for this one
  CheckoutStarted = 'Checkout Started',
  CheckoutStepCompleted = 'Checkout Step Completed',
  CheckoutStepViewed = 'Checkout Step Viewed',
  ManualSizeSelected = 'Manual Size Selected',
  OrderCompleted = 'Order Completed',
  PageViewed = 'Page View',
  PageScrolled = 'Page Scrolled',
  ProductAdded = 'Product Added',
  ProductClicked = 'Product Clicked',
  ProductRemoved = 'Product Removed',
  ProductViewed = 'Product Viewed',
  SizingKitSelected = 'Sizing Kit Selected',
  UserEmailCaptured = 'User Email Captured',
  UserBillingAddressCaptured = 'User Billing Address Captured',
  UserShippingAddressCaptured = 'User Shipping Address Captured',
  PlaceOrderButtonClicked = 'User Clicked Place Order Button',
  OneClickPaymentButtonClicked = 'User Clicked One Click Payment Button',
  OneClickPaymentButtonCanceled = 'User Exited One Click Payment Modal',
}

export const EventName = { ...GA4Name, ...AnalyticsName };
export type EventName = GA4Name | AnalyticsName;

export interface PayloadItems {
  action?: string;
  ambassadorName?: string;
  analytics_category?: string;
  carouselNumberInOrder?: number;
  carouselTitle?: string;
  contentDuration?: number;
  contentTitle?: string;
  contentType?: string;
  cta?: string;
  email?: string;
  experiment_id?: string;
  experiment_name?: string;
  first_name?: string;
  isGift?: boolean;
  last_name?: string;
  handle?: ListProduct['handle'];
  hasGiftMessage?: boolean;
  images?: ListProduct['images'];
  location?: string;
  module?: string;
  page?: string;
  threshold?: number;
  path?: string;
  payment_method?: string | null;
  postal?: string;
  price?: ListProduct['price'] | null;
  product?: ListProduct | undefined;
  ringType?: string;
  stockStatus?: StockStatus;
  timeElapsed?: number;
  title?: string;
  type?: EventType;
  variant_id?: string;
  variant_name?: string;
  variants?: Variant[];
  slide?: number;
}

export interface Payload {
  payload: PayloadItems;
  type: EventType;
}

export interface IdentifyPayload {
  payload?: PayloadItems;
  identify: string | { email: string };
}

export interface BaseDataLayer {
  event: EventName | EventType;
}

export interface Address {
  address: string;
  address2: string;
  city: string;
  country: string;
  email: string;
  fname: string;
  lname: string;
  phone: string;
  postal: string;
  state: string;
}

export interface BaseProductType {
  brand: string;
  category: string;
  coupon: string;
  image_url: string;
  name: string;
  position: number;
  price: number;
  product_id: string | number;
  quantity: number;
  sku: string;
  url: string;
  variant: string | number;
  currency: string;
  fbc: string | null;
  fbp: string | null;
}

export interface SegmentProductAddType {
  cart_id: string | false;
}

export type SegmentProductPayloadType =
  | BaseProductType
  | (BaseProductType & SegmentCartViewType);

export interface SegmentCartViewType {
  cart_id: string | false;
  products: BuildCartLineItems[];
}

export interface SegmentCheckoutStartType {
  affiliation: string;
  coupon: string;
  currency: string;
  discount: number;
  order_id: string;
  revenue: number;
  shipping: number;
  tax: number;
  value: number;
  products: BuildCartLineItems[];
  fbc: string | null;
  fbp: string | null;
}

export interface BaseSegmentPayloadType {
  checkout_id: string;
  step: EventType;
  campaign?: string;
}

export interface ShippingMethodType {
  shipping_method?: string;
}

export interface PaymentMethodType {
  payment_method: string | null;
}

export type SegmentPayloadType = BaseSegmentPayloadType;

export interface BuildCartLineItems {
  id?: string | number;
  product_id?: number;
  sku: string;
  name: string;
  price: number;
  position: number;
  quantity: number;
  category: string;
  url: string;
  image_url: string;
}

export interface BuildAnalyticsCartItems {
  item_id: string | number;
  item_name: string;
  price: number;
  quantity: number;
  item_category: string;
  coupon: string;
  currency: string;
  discount: number;
  item_variant: string | number;
  item_brand: string;
}

export type AnalyticsPayload =
  | SegmentProductPayloadType
  | SegmentCartViewType
  | SegmentCheckoutStartType
  | SegmentPayloadType
  | PayloadItems;

export type AnalyticsTraits = Partial<{
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: {
    city: string;
    postalCode: string;
    state: string;
    country: string;
  };
  products: BuildCartLineItems[];
}>;

export interface ItemType {
  coupon: string | null;
  currency: string;
  discount: number;
  item_brand: string;
  item_category: string;
  item_id: string | number;
  item_name: string;
  item_variant: string | number;
  price: number;
  quantity: number;
}

export interface BaseGA4Type {
  items: ItemType[];
}

export interface PartialGA4ItemType {
  items: Partial<ItemType>[];
}

export interface GA4CartViewType {
  currency: string;
  value: number;
}

export interface GA4OrderCompleteType {
  coupon: string;
  payment_type: string;
}

export interface GA4OrderType {
  affiliation: string;
  coupon: string;
  currency: string;
  tax: number;
  transaction_id: string;
  value: number;
}

export interface GA4CheckoutStepType {
  currency: string;
  value: number;
  coupon: string;
  shipping_tier: string;
}

export type GA4PayloadType =
  | BaseGA4Type
  | (BaseGA4Type & GA4CartViewType)
  | (BaseGA4Type & GA4CartViewType & GA4OrderCompleteType)
  | (BaseGA4Type & GA4OrderType)
  | (BaseGA4Type & GA4CheckoutStepType);

export interface ActionField {
  list?: AnalyticsName | EventName;
  step?: number;
  options?: string;
  option?: string;
  id?: string | number;
  coupon?: string;
  affiliation?: string;
  revenue?: number;
  tax?: number;
  shipping?: number;
}

export interface DataLayer extends BaseDataLayer {
  ecommerce_data?: PartialGA4ItemType | GA4PayloadType;
  eventPath?: string;
  eventAttributes?: PayloadItems;
}
